import React, { Fragment } from 'react'
import { Formik} from "formik";
import Button from 'mineral-ui/Button'
import Loading from '../../components/Loading'
import { Modal, Close, SuccessText, ErrorText, Text, Title, FormContainer, SubTitle, TextInput, DeleteButton } from '../../theme'

/*
  Delete Azure Vault Key
  @prop {string} vault - vault name
  @prop {string} keyName - key name
*/
export default ({ vault, keyName }) => {
  // Set up States
  const [showForm, setShowForm] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  // Form Toggle
  function toggleForm() {
    setSubmitted(false)
    setShowForm(!showForm)
  }

  // Form Submit
  async function onSubmit({ entry }, { setSubmitting, setErrors }) {
    setSubmitting(true);
    if(entry === keyName) {
      const response = await fetch(`/api/internal/key-vaults?action=keys&vaultname=${vault}&secretname=${keyName}`, 
        {method: 'DELETE', headers: {apikey: 'b7c4e1c4-80b3-4aed-af33-58507218395d'}})
      const data = await response.json()
      if(data.error){
          setErrors({ key: data.error })
          setSubmitted(false)
      } else {
          setSubmitted(true)
      }
    } else {
      setErrors({ key: 'Key name does not match' })
    }
    setSubmitting(false);
  }

  return (
    <Fragment>
        <DeleteButton size='1.5em' key='deleteButton' color='#FE0001' onClick={toggleForm}/>
        {showForm ? (
            <Modal>
            <Close onClick={toggleForm} />
            <Formik
                initialValues={{ entry: ''}}
                onSubmit={onSubmit}
            >
                { ({ errors, isSubmitting}) => (
                <FormContainer>
                    <Title>Delete Key</Title>
                    <Title>{keyName}</Title>
                    <SubTitle>Vault: {vault}</SubTitle>
                    <Text>
                        Confirm deletion by retyping Key:
                        <TextInput type="text" name="entry" />
                    </Text>
                    <Text>
                        {!submitted || errors.key ? <Button
                            css={{ textTransform: 'uppercase' }}
                            type="submit"
                            primary
                            iconEnd={isSubmitting ? <Loading /> : null}
                        >
                            {isSubmitting ? '' : 'Submit'}
                        </Button> :
                        <Button primary onClick={toggleForm}>Close</Button>}
                    </Text>
                    {(errors.key || submitted) && (
                        <Text>
                            {errors.key && <ErrorText>{errors.key}</ErrorText>}
                            {submitted && !errors.key && <SuccessText>Secret Succesfully Deleted</SuccessText>}
                        </Text>
                    )}
                </FormContainer>
                )}
            </Formik>
            </Modal>
        ) : null}
    </Fragment>
  );
}