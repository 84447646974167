import React from 'react'
import { MsalProvider } from './msal-context'

function AppProviders({ children }) {
  return (
    <MsalProvider>
      { children }
    </MsalProvider>
  )
}

export default AppProviders
