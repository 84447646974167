import React, { Fragment, useEffect } from 'react'
import Text from 'mineral-ui/Text'
import { IconClose, IconScreenLockPortrait, IconCached } from 'mineral-ui-icons'
import Tooltip from 'mineral-ui/Tooltip'
import styled from 'styled-components'
import Button from 'mineral-ui/Button'
import Loading from '../../components/Loading'
import Table from 'mineral-ui/Table'
import StartEnd from 'mineral-ui/StartEnd';

function GetAuthCodes({ userId, phoneNumber, client }) {
  const [showForm, setShowForm] = React.useState(false)
  const [messages, setMessages] = React.useState([])
  const [fetching, setFetching] = React.useState(false)

  useEffect(() => {
    if(phoneNumber && showForm) {
      getCodes(phoneNumber, 5)
    }
  }, [showForm])


  function toggleForm() {
    setShowForm(!showForm)
  }

  async function getCodes( phoneNumber, limit ) {
    setFetching(true)
    setMessages([])
    console.log('Getting Messages')
    const messages = await fetch('api/twilio', {
      method: 'GET',
      headers: {
        phonenumber: phoneNumber,
        client: client,
        limit: limit
      }
    })
    let data = await messages.json()
    for (let i = 0; i < data.length; i++) {
      data[i].dateSent = new Date(data[i].dateSent).toLocaleString()
    }

    setFetching(false)
    setMessages(data)
  }

  const columns = [
    { content: 'Message', key: 'body', sortable: false },
    { content: 'Date', key: 'dateSent', sortComparator: (a, b) => new Date(a.dateSent) < new Date(b.dateSent) }
  ]

  return (
    <Fragment>
      {phoneNumber ? (
      <Tooltip
        cursor="pointer"
        placement='top'
        content='2FA Codes'
      >
        <ToggleButton size='1.5em' onClick={toggleForm} color='green'/>
      </Tooltip>
      ): (
        <IconScreenLockPortrait size='1.5em' color='red' opacity='0.5' />
      )
      }
      {showForm && phoneNumber ? (
        <Modal>
          <Close onClick={toggleForm} />
          <TableContainer>
          <StartEnd>
            <Header>
              Phone Number: {phoneNumber ? phoneNumber.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : 'No Phone Number'}
            </Header>
            <RefreshButton size='1.5em' onClick={() => getCodes(phoneNumber, 5)} />
          </StartEnd>
            <Table
              title="2FA Codes"
              data={messages}
              columns={columns}
              hideTitle
              defaultSort={{key: 'dateSent'}}
              sortable
              striped
              density='spacious'
            />
            {fetching ? 
              <Text>Loading...</Text>: null
            } 
          </TableContainer>
        </Modal>
      ) : null}
    </Fragment>
  );
}

export default GetAuthCodes

const TableContainer = styled.div`
  width: auto;
  min-width: 1000px;
  margin: auto;
  height: 57%;
  padding: 6%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.45);
  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      border-bottom: 1px solid #ccc;
      td {
        padding: 10px;
      }
    }
  }
`


const ToggleButton = styled(IconScreenLockPortrait)`
  background-color: transparent;
  &:hover {
    color: #5CAD5C;
  }
`

const RefreshButton = styled(IconCached)`
  background-color: transparent;
  margin: 10px;
  transition: all 0.5s ease;
  &:hover {
    color: darkgrey;
    transform: rotate(-45deg);
  }
  &:active {
    color: black;
  }
`
const Header = styled.div`
  margin: 10px;
`

const Close = styled(IconClose)`
  position: fixed;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 20px;
  cursor: pointer;
  path {
    stroke: white;
    stroke-width: 2px;
  }
`
const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 5% 5%;
  animation: openMap 0.5s;
  @keyframes openMap {
    from {
      opacity: 0;
      transform: scale(1.025);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`

