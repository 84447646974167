import React from 'react'
import ReactDOM from 'react-dom'
import { createTheme, ThemeProvider } from 'mineral-ui/themes'
import { Provider as AlertProvider, positions } from 'react-alert'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'

import AppProviders from './context'
import App from './app'
import Message from './components/Message'


const theme = createTheme({
  colors: {
    theme: 'indigo'
  }
})

const client = new ApolloClient({
  uri: "/api/graphql"
})

const alertOptions = {
  timeout: 7000,
  position: positions.TOP_CENTER,
  containerStyle: {
    zIndex: 1000,
    maxWidth: '80%'
  }
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <AlertProvider template={Message} {...alertOptions}>
        <AppProviders>
          <App />
        </AppProviders>
      </AlertProvider>
    </ApolloProvider>
  </ThemeProvider>
  , document.getElementById('root')
)
