import React from 'react'
import Message from './Message'
import { useNavigate } from "@reach/router"

/*
    Wraps components that require authentication.
    @prop {string/boolean} routeKey - Value to compare 
    @prop {string/boolean} routeLock - Value to compare against
    @prop {string} redirect - Route to redirect to if bad key
    @prop {React.Component} Component - Component to wrap
*/
export default ({redirect='/'}) => {
    const navigate = useNavigate()
    const options = {
        type: 'error'
    }
    return <Message 
        message='404: Page Not Found' 
        options={options}
        close={() =>{navigate(redirect)}}/>
}