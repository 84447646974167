import React from 'react'
import Box from 'mineral-ui/Box'
import Text from 'mineral-ui/Text'
import Button from 'mineral-ui/Button'
import Dropdown from 'mineral-ui/Dropdown';
import styled from '@emotion/styled'
import PaginatedTable from '../../components/PaginatedTable'
import DeleteKey from './deleteKey'
import CreateKey from './createKey'
import { IconRefresh, IconContentCopy } from 'mineral-ui-icons'
import UpdateKey from './updateKey'
import Tooltip from 'mineral-ui/Tooltip'
import Loading from '../../components/Loading'
import theme, { Flex, CopyToClipboard, FlexSubContainer } from '../../theme'

/*
    Key Vault Screen
    No props required
*/
export default () => {
    // Set up states
    const [selected, setSelected] = React.useState('Select Vault')
    const [keys, setKeys] = React.useState([])
    const [vaults, setVaults] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    // Get Keys
    function getKeys(){
        // Clear Keys
        setKeys([])
        setLoading(true)
        // Get Keys
        fetch(`/api/internal/key-vaults?action=keys&vaultname=${selected.name}&resourcegroup=${selected.resourceGroup}`, {headers: {apikey: 'b7c4e1c4-80b3-4aed-af33-58507218395d'}})
        .then(res => res.json())
        .then(data => {
            // Parse data for table use
            return data.map(key => Object.assign({}, key, { info: { vault: selected.name, name: key.name, value: key.value}}))
        })
        .then(data => {
            setLoading(false)
            setKeys(data)
        })
    }

    // Get Vaults
    function getVaults(){
        // get vaults from internal api
        fetch('/api/internal/key-vaults?action=vaults', {headers: {apikey: 'b7c4e1c4-80b3-4aed-af33-58507218395d'}})
        .then(res => res.json())
        .then(vaults => {
            let parsedVaults = []
            for(let vault of vaults){
                parsedVaults.push({
                    text: vault.name,
                    resrouceGroup: vault.resourceGroup,
                    onClick: () => {
                        setSelected(vault)
                    }
                })
            }
            return parsedVaults.sort((a, b) => a.text.localeCompare(b.text))
        }).then( parsedVaults => setVaults(parsedVaults))
    }

    // Initialization Hook
    React.useEffect(() => {
        getVaults()
    }, [])

    // Get Keys on vault change
    React.useEffect(() => {
        if(selected !== 'Select Vault'){
            getKeys()
        }
    }, [selected])

    // Define Columns
    const columns = [
        { content: 'Secret Name', key: 'name', width: '75%' },
        { content: 'Status / Actions', key: 'info', cell }
    ]

    // Define Table Controls
    const disable = loading || selected === 'Select Vault'
    const controls = []
    controls.push(<CreateKey vault={selected.name} key='createKey' disabled={disable}/>)
    controls.push(<Button key='refresh' onClick={getKeys} iconStart={<IconRefresh />} disabled={disable}/>)

    return (
        <Box padding="lg" >
            <Flex className='space-between'>
                <Text as="h1">Azure Key Vault Manager</Text>
                <FlexSubContainer>
                    <Dropdown data={vaults} disabled={vaults.length === 0}>
                        {vaults.length > 0 ? <Button>{selected.name ? selected.name : selected}</Button> : <Button iconStart={<Loading fill={theme.primaryColor}/>}></Button>}
                    </Dropdown>
                </ FlexSubContainer>
            </Flex>
            <PaginatedTable
                loading={loading}
                data={keys}
                columns={columns}
                pageSize={10}
                padding="2em"
                controls={controls}
            />
        </Box>
    )
}
 
// Define Actions Cell
const ActionsCell = props => {
    return (
        <Root className='space-between' {...props}>
            <UpdateKey
                keyName={props.children.name}
                vault={props.children.vault}
            />
            <CopyToClipboard
                text={props.children.value}
                >
                <Tooltip
                    cursor="pointer"
                    placement='top'
                    content='Copy key to clipboard'
                >
                    <IconContentCopy size="1.5em"/>
                </Tooltip>
            </CopyToClipboard>
            <DeleteKey
                keyName={props.children.name}
                vault={props.children.vault}
            />
        </Root>
    )
}

// Style Actions Cell
const Root = styled('td')(({ theme }) => ({
    padding: theme.space_stack_sm + ' ' + theme.space_inline_md,
    alignItems: 'center',
    display: 'flex',
    '&.flex-left': {
        justifyContent: 'flex-start',
        '& *:not(:last-child)': {
            marginRight: theme.space_inline_md
        }
    },
    '&.space-between': {
        justifyContent: 'space-between',
    }
}));
  
const cell = ({ props }) => <ActionsCell {...props} />