import React, { Fragment } from 'react'
import { IconAdd } from 'mineral-ui-icons'
import { Formik } from "formik";
import Button from 'mineral-ui/Button'
import Loading from '../../components/Loading'
import { Modal, Close, SuccessText, ErrorText, Text, Title, FormContainer, SubTitle, TextInput} from '../../theme'

/*
  Create Azure Vault Key
  @prop {string} vault - vault name
  @prop {boolean} disable - disable button
*/
export default ({ vault, disabled }) => {
  // Set up States
  const [showForm, setShowForm] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  // Form Toggle
  function toggleForm() {
    setSubmitted(false)
    setShowForm(!showForm)
  }

  // Form Submit
  async function onSubmit({ key, value }, { setSubmitting, setErrors }) {
    setSubmitting(true);
    const response = await fetch(`/api/internal/key-vaults?action=keys&vaultname=${vault}&secretname=${key}&secretvalue=${value}`, 
    {method: 'POST', headers: {apikey: 'b7c4e1c4-80b3-4aed-af33-58507218395d'}})
    const data = await response.json()
    if(data.error){
        console.log(data.error)
        setErrors({ key: data.error })
        setSubmitted(false)
    } else {
        setSubmitted(true)
    }
    setSubmitting(false);
  }

  return (
    <Fragment>
        <Button iconStart={<IconAdd />} variant='success' key='addButton' onClick={toggleForm} disabled={disabled}>Add Key</Button>
        {showForm ? (
            <Modal>
            <Close onClick={toggleForm} />
            <Formik
                initialValues={{ key: '', value: '' }}
                onSubmit={onSubmit}
            >
                { ({ errors, isSubmitting}) => (
                <FormContainer>
                    <Title>Create New Key</Title>
                    <SubTitle>Vault: {vault}</SubTitle>
                    <Text>
                        Name:
                        <TextInput type="text" name="key" />
                    </Text>
                    <Text>
                        Value:
                        <TextInput type="text" name="value" />
                    </Text>
                    <Text>
                        {!submitted || errors.key ? <Button
                            css={{ textTransform: 'uppercase' }}
                            type="submit"
                            primary
                            iconEnd={isSubmitting ? <Loading /> : null}
                        >
                            {isSubmitting ? '' : 'Submit'}
                        </Button> :
                        <Button primary onClick={toggleForm}>Close</Button>}
                    </Text>
                    {(errors.key || submitted) && (
                        <Text>
                            {errors.key && <ErrorText>{errors.key}</ErrorText>}
                            {submitted && !errors.key && <SuccessText>Secret Succesfully Created</SuccessText>}
                        </Text>
                    )}
                </FormContainer>
                )}
            </Formik>
            </Modal>
        ) : null}
    </Fragment>
  );
}