import React from 'react'
import Text from 'mineral-ui/Text'
import Box from 'mineral-ui/Box'
import Table from 'mineral-ui/Table'
import { useQuery } from '@apollo/react-hooks'
import styled from '@emotion/styled'
import gql from 'graphql-tag'
import { IconSuccess, IconWarning, IconContentCopy } from 'mineral-ui-icons'
import Tooltip from 'mineral-ui/Tooltip'
import UpdatePassword from './updatePassword'
import Loading from '../../components/Loading'
import theme, { Flex, CopyToClipboard } from '../../theme'
import GetAuthCodes from './getAuthCodes'

const GET_CLIENTS = gql`
  query {
    clients {
      name
      accounts {
        id
        userId
        supplierName
        passwordExpired
        password
        group
        updatedAt
        freightAccess
        phoneNumber
      }
    }
  }
`

export default ({ user }) => {
  const { loading, data } = useQuery(GET_CLIENTS)
  const tableData = !loading && data.clients.flatMap(client => {
    return client.accounts.map(act => {
      var daysRemaining = Math.floor((Date.now() - Date.parse(act.updatedAt)) / (1000*60*60*24))
      var expiring = daysRemaining >= 53 || act.passwordExpired
      var color = daysRemaining > 59 || act.passwordExpired ? 'red' : 'orange'
      return {
        client: act.supplierName,
        reportingClient: client.name,
        userId: act.userId,
        password: { 
          text: act.password, 
          expiring: expiring, 
          days: daysRemaining, 
          colors: color, 
          userId: act.userId, 
          accountId: act.id, 
          login:user.userName, 
          phoneNumber: act.phoneNumber,
          reportingClient: client.name
        },
        group: act.group,
        updated: act.updatedAt,
        freightAccess: act.freightAccess
      }
    })
  })

  //Creates an array of the users groups
  let userGroups = []
  user.groups.forEach(userGroup => {
    userGroups.push(userGroup.name)
  })

  //Creates an array of the users clients
  let clients = []

  //Filters the Table based on the users group
  let filteredTableData = !tableData ? [] : tableData.filter(tableRow => {
    // Internal Access
    if(userGroups.includes(tableRow.group) || userGroups.includes('Data')) {
      if(!clients.includes(tableRow.reportingClient)){
        clients.push(tableRow.reportingClient)
      }
      return true
    } else if( user.userName.includes('bminwa.com') && tableRow.group === 'Bentonville Management'){
      if(!clients.includes(tableRow.reportingClient)){
        clients.push(tableRow.reportingClient)
      }
      return true
    // Freight Access
    } else if( userGroups.includes('Nile Fulfillment') && tableRow.freightAccess === true) {
      if(!clients.includes(tableRow.reportingClient)){
        clients.push(tableRow.reportingClient)
      }
      return true
    }
    return false
  })

  const columns = [
    { content: 'Client', key: 'client', width: '25%'  },
    { content: 'Retail Link ID', key: 'userId', width: '50%' },
    { content: 'Status / Actions', key: 'password', cell },
  ]

  const tables = []
  clients.forEach(element => {
    tables.push(
      <Text as = 'h3' key={element + "-title"}>{element}</Text>
    )
    tables.push(
    <Table
      title="Retail Link Accounts"
      data={filteredTableData.filter(tableRow => tableRow.reportingClient === element)}
      columns={columns}
      key={element}
      hideTitle
      defaultSort={{key: 'client', direction: 'asc'}}
      sortable
      def
      striped
      density='spacious'
    />)
    tables.push(<br key={element + "-break"}/>)
  })

  return (
    <Box padding="lg" >
      <Text as="h1">Manage Passwords</Text>
      <Box padding="2em" >
        {loading ? 
          <Flex className='center'> 
            <Loading fill={theme.primaryColor}/>
          </Flex> : 
          (tables)}
      </Box>
    </Box>
  )
}

const Root = styled('td')(({ theme }) => ({
  padding: theme.space_stack_sm + ' ' + theme.space_inline_md,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
}));

const ActionsCell = props => {
  return (
    <Root {...props}>
      <Tooltip
        cursor="pointer"
        placement='top'
        content={'Days Old: ' + props.children.days}
      >
        {props.children.expiring ?
          <IconWarning size='1.7em' color={props.children.colors}/>:
          <IconSuccess size='1.7em' color='green'/>
        }
      </Tooltip>
      <UpdatePassword
        accountId={props.children.accountId}
        password={props.children.password}
        userId={props.children.userId}
      />
      <CopyToClipboard
        text={props.children.text}
      >
        <Tooltip
          cursor="pointer"
          placement='top'
          content='Copy password to clipboard'
        >
          <IconContentCopy size="1.5em"/>
        </Tooltip>
      </CopyToClipboard>
      <GetAuthCodes
        phoneNumber={props.children.phoneNumber}
        userId={props.children.userId}
        client={props.children.reportingClient}
      />
    </Root>
  )
}

const cell = ({ props }) => <ActionsCell {...props} />