import React from 'react'
import { Router } from '@reach/router'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import Box from 'mineral-ui/Box'
import { PrimaryNav, NavItem } from 'mineral-ui/Navigation'
import { useMsalAuth } from './context/msal-context'

import theme, { GlobalStyle, Flex, Main, Background, AppScreen } from './theme'
import Header from './components/Header'
import SecureRoute from './components/SecureRoute'
import Loading from './components/Loading'
import Suppliers from './screens/suppliers'
import Bots from './screens/bots'
import KeyVault from './screens/key-vault'
import NotFound from './components/NotFound'

export default (props) => {
  // Set up States
  let { user, login } = useMsalAuth()
  // Set up main Variables
  const currentPath = window.location.pathname

  // Load User
  React.useLayoutEffect(() => {
    if(!user) {
      login()
    }
  }, [user, login])

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        <Helmet>
          <title>Linker</title>
        </Helmet>
          <Header />
          <Main>
            <Background/>
            <AppScreen>
              <NavBarStyled maxItemWidth='100%'>
                <NavItem href='/' selected={currentPath==='/'} disabled={currentPath==='/'}>Shared Retail Link Accounts</NavItem>
                {user && user.admin ? <NavItem href='/key-vault' selected={currentPath==='/key-vault'} disabled={currentPath==='/key-vault'}>Key Vault Manager</NavItem> : <div hidden />}
              </NavBarStyled>
              {user ? (
                <div>
                  <Router primary={false}>
                    <Suppliers user={user} path="/" />
                    <Bots user={user} path="/bots" />
                    <SecureRoute 
                      routeKey={user.admin}
                      component={<KeyVault user={user} />}
                      path="/key-vault"
                    />
                    <NotFound default />
                  </Router>
                </div>
              ):(
                <Box padding="lg">
                  <Flex className='center'>
                    <Loading fill={theme.primaryColor}/>
                  </Flex>
                </Box>
              )}
            </AppScreen>
          </Main>
      </React.Fragment>
    </ThemeProvider>
  )
}

const NavBarStyled = styled(PrimaryNav)`
    border-radius: 10px 10px 0 0;
`
