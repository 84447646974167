import React from 'react'
import Box from 'mineral-ui/Box'
import Table from 'mineral-ui/Table'
import Pagination from 'mineral-ui/Pagination'
import Loading from './Loading'
import theme, { Flex } from '../theme'

/*
    Paginated Table with controls
    @prop {boolean} loading - boolean to show loading status
    @prop {object} data - array of data to display
    @prop {number} pageSize - number of items per page
    @prop {object} columns - array of column definitions
    @prop {string} padding - padding for table
    @prop {object} controls - array of controls to display
*/
export default ({ loading, data, pageSize, columns, padding, controls }) => {
    // Set States
    const [ currentPage, setCurrentPage ] = React.useState(1)

    // Hook to reset Page if reloading data
    React.useEffect(() => {
        if(loading) {
            setCurrentPage(1)
        }
    }, [loading])

    // Calculate page info
    const firstRow = (currentPage - 1) * pageSize
    const lastRow = firstRow + pageSize
    const slicedData = data.slice(firstRow, lastRow)

    return (
        <Box padding={padding} >
            {controls && 
            (<Flex className='flex-end' padding='.5em'>
                {controls}
            </Flex>)}
            <Table
                title="Vault Keys"
                data={slicedData}
                columns={columns}
                key="name"
                hideTitle
                defaultSort={{key: 'name', direction: 'asc'}}
                sortable
                def
                striped
                density='spacious'
            />
            {loading && (
                <Flex className='center'> 
                    <Loading fill={theme.primaryColor}/>
                </Flex>)}
            {data.length > pageSize && <Pagination
              padding='.5em' 
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              pageSize={pageSize}
              totalCount={data.length} 
            />}
        </Box>
    )
}