import React from 'react';

export default props => (
  <svg width={80} height={20} fill="#fff" {...props}>
    <circle cx={20} cy={10} r={8}>
      <animate
        attributeName="r"
        from={8}
        to={8}
        begin="0s"
        dur="0.8s"
        values="8;5;8"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fill-opacity"
        from={1}
        to={1}
        begin="0s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx={40} cy={10} r={5} fillOpacity={0.3}>
      <animate
        attributeName="r"
        from={5}
        to={5}
        begin="0s"
        dur="0.8s"
        values="5;8;5"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fill-opacity"
        from={0.5}
        to={0.5}
        begin="0s"
        dur="0.8s"
        values=".5;1;.5"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx={60} cy={10} r={8}>
      <animate
        attributeName="r"
        from={8}
        to={8}
        begin="0s"
        dur="0.8s"
        values="8;5;8"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fill-opacity"
        from={1}
        to={1}
        begin="0s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
)
