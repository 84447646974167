import React from 'react'
import { red_50, green_50, slate_50, gray_90 } from 'mineral-ui-tokens'
import { IconErrorOutline, IconSuccess, IconInfo } from 'mineral-ui-icons'
import Box from 'mineral-ui/Box'
import Text from 'mineral-ui/Text'
import { MessageContainer, MessageHeader, MessageIconContainer, MessageCloseContainer, MessageClose } from '../theme'

export default ({ style, options, message, close }) => {
  let color, AlertIcon, title

  if(options.type === 'error') {
    color = red_50
    title = "Error"
    AlertIcon = IconErrorOutline
  } else if(options.type === 'success') {
    color = green_50
    title = "Success"
    AlertIcon = IconSuccess
  } else {
    color = slate_50
    title = "Info"
    AlertIcon = IconInfo
  }

  return (
    <MessageContainer style={{...style, margin: '10px auto'}}>
      <MessageHeader padding="sm" css={{paddingBottom: '.2em'}}>
        <Box css={{ display: 'flex'}}>
        <MessageIconContainer>
          <AlertIcon size="1.5em" color={color} />
        </MessageIconContainer>
        <Text css={{ marginBottom: 0, color: color}} appearance="h4">{title}</Text>
        </Box>
        <MessageCloseContainer onClick={close}>
          <MessageClose color={gray_90} size="large"  />
        </MessageCloseContainer>
      </MessageHeader>
      <Box padding="md" css={{paddingTop: '.2em'}}>{ message }</Box>
    </MessageContainer>
  )
}
