import React from 'react'
import { useMsalAuth } from './../context/msal-context'
import StartEnd from 'mineral-ui/StartEnd'
import Avatar from 'mineral-ui/Avatar'
import Flex, { FlexItem }  from 'mineral-ui/Flex'
import { HeaderContainer, Header, MainLogo } from '../theme'

export default () => {
  const { user } = useMsalAuth()

  return (
    <HeaderContainer>
      <Header>
        <StartEnd
          justifyContent="center"
          padding="sm"
          css={{ height: '65px' }}
        >
          <Flex
            css={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <FlexItem as={MainLogo} css={{ width: 'auto', height: '25px'}} />
            <FlexItem
              css={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: '28px',
                fontFamily: "'Source Sans Pro'",
                '@media (max-width: 490px)': {
                  display: 'none'
                }
              }}
            >

            </FlexItem>
          </Flex>
              <Flex css={{height: '100%'}} padding="sm">
                {user && (
                  <Avatar
                    css={{ marginRight: '15px'}}
                    as="a"
                    // onClick={logout}
                    target="_blank"
                    href="https://portal.office.com"
                  >
                    <img src={user.picture} alt="avatar" />
                  </Avatar>
                )}
              </Flex>
        </StartEnd>
      </Header>
    </HeaderContainer>
  )
}