import React from 'react'
import Message from './Message'
import { useNavigate } from "@reach/router"

/*
    Wraps components that require authentication.
    @prop {string/boolean} routeKey - Value to compare 
    @prop {string/boolean} routeLock - Value to compare against
    @prop {string} redirect - Route to redirect to if bad key
    @prop {React.Component} Component - Component to wrap
*/
export default ({ routeKey, routeLock=true, redirect='/', component }) => {
    // Turn Key
    if (routeKey === routeLock){
        return component
    // Flag and redirect if Key doesn't match
    } else {
        const navigate = useNavigate()
        const options = {
            type: 'error'
        }
        return <Message 
            message='You do not have permission to access this page' 
            options={options}
            close={() =>{navigate(redirect)}}/>
    }
}