import React from 'react'
import { UserAgentApplication } from 'msal'

const MsalContext = React.createContext()

function MsalProvider(props) {

  const msalConfig = {
    auth: {
      clientId: '4253dd49-3ede-4459-b8b5-85f6c984c50e',
      authority: 'https://login.microsoftonline.com/common',
      postLogoutRedirectUri: 'https://www.merchantlabs.io'
    },
    cache: {
      storeAuthStateInCookie: true
    }
  }

  const msal = new UserAgentApplication(msalConfig)

  const [user, setUser] = React.useState(null)

  msal.handleRedirectCallback((error, response) => {
    if(error) {
      console.log(error)
    }
  })

  function login() {
    var loginRequest = {
      scopes: ["user.read", "profile"]
    }

    msal.acquireTokenSilent(loginRequest)
    .then(response => {
      var headers = new Headers();
      var bearer = "Bearer " + response.accessToken;
      headers.append("Authorization", bearer);
      headers.append('Content-type', 'application/json')
      var options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          "requests":[
            {
              "url":"/me/photo/$value",
              "method":"GET",
              "id":"1"
            },{
              "url":"/me/memberOf",
              "method":"GET",
              "id":"2"
            }
          ]
        })
      };

      var graphEndpoint = `https://graph.microsoft.com/v1.0/$batch`

      fetch(graphEndpoint, options)
      .then(res => res.json())
      .then(({responses}) => {
        const user = msal.getAccount()

        responses.forEach(res => {
          if(res.id === "1") {
            if(res.status === 404) {
              user.picture = `https://ui-avatars.com/api/?size=128&name=${user.name}&background=0D8ABC&color=fff&bold=true`
            } else {
              user.picture = `data:${res.headers['Content-Type']};base64,${res.body}`
            }
          } else if (res.id === "2") {
            user.groups = res.body.value
            .filter(x => x['@odata.type'] === '#microsoft.graph.group')
            .map(x => ({
              id: x.id,
              email: x.mail,
              description: x.description,
              name: x.displayName
            }))
            user.admin = user.groups.find(group => group.name === 'Data') ? true : false
          }
        })

        setUser(user)
      })
      .catch(err => {
        const user = msal.getAccount()
        user.picture = `https://ui-avatars.com/api/?size=128&name=${user.name}&background=0D8ABC&color=fff&bold=true`
        setUser(user)
      })
    })
    .catch(err => {
      msal.loginRedirect(loginRequest)
    })
  }

  function logout() {
    msal.logout()
  }


  return (
    <MsalContext.Provider
    value={{ login, logout, user }}
    {...props}
    />
  )
}

function useMsalAuth() {
  const context = React.useContext(MsalContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a MsalProvider`)
  }
  return context
}

export { MsalProvider, useMsalAuth }
