import React, { Fragment } from 'react'
import { IconModeEdit } from 'mineral-ui-icons'
import { Formik } from "formik";
import Tooltip from 'mineral-ui/Tooltip'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components'
import Button from 'mineral-ui/Button'
import Loading from '../../components/Loading'
import { Modal, Close, PasswordInput, SuccessText, ErrorText, Text, Title, FormContainer, SubTitle } from '../../theme'

const UPDATE_ACCOUNT = gql`
  mutation UpdateMutation($accountId: ID!, $userId: String!, $password: String!) {
    updateRetailLinkAccount(
      accountId: $accountId,
      userId: $userId,
      password: $password
    ) {
      id
      userId
      group
      password
      accessType
    }
  }
`

function UpdatePassword({ accountId, userId }) {
  const [showForm, setShowForm] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const [updateRetailLinkAccount] = useMutation(UPDATE_ACCOUNT)

  function toggleForm() {
    setShowForm(!showForm)
  }

  async function onSubmit({ password }, { setSubmitting, setErrors }) {
    setSubmitting(true);

    try {
      await updateRetailLinkAccount( {variables: {
        accountId,
        userId,
        password
      } } )
      setSubmitted(true);
    } catch (e) {
      console.log(e)
      if(e.code === 401) {
        setErrors({ password: 'Incorrect Password'})
      } else if(e.code !== 502) {
        setErrors({ password: 'An error occurred. Please Refresh'})
      }
    }
  }



  return (
    <Fragment>
      <Tooltip
        cursor="pointer"
        placement='top'
        content='Update Password'
      >
        <ToggleButton size='1.5em' onClick={toggleForm}/>
      </Tooltip>
      {showForm ? (
        <Modal>
          <Close onClick={toggleForm} />
          <Formik
            initialValues={{ password: '' }}
            onSubmit={onSubmit}
          >
            { ({ errors, isSubmitting}) => (
              <FormContainer>
                <Title>Update Password</Title>
                <SubTitle>Retail Link ID: {userId}</SubTitle>
                <Text>
                  Password:
                  <PasswordInput type="password" name="password" />
                </Text>
                {errors.password && <ErrorText>{errors.password}</ErrorText>}
                {submitted && !errors.password && <SuccessText>Password Successfully Updated</SuccessText>}
                <Text>
                  <Button
                    css={{ textTransform: 'uppercase' }}
                    type="submit"
                    primary
                    iconEnd={isSubmitting ? <Loading /> : null}
                  >
                    {!isSubmitting ? 'Submit' : ''}
                  </Button>
                </Text>
              </FormContainer>
            )}
          </Formik>
        </Modal>
      ) : null}
    </Fragment>
  );
}

export default UpdatePassword

const ToggleButton = styled(IconModeEdit)`
  background-color: transparent;
  &:hover {
    color: darkgrey;
  }
`