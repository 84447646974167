import { createGlobalStyle } from 'styled-components'
import { CopyToClipboard as CopyToClipboardBase } from 'react-copy-to-clipboard'
import styled from 'styled-components'
import { IconClose, IconDelete, IconModeEdit } from 'mineral-ui-icons'
import { Field, Form } from "formik";
import { gray_20, boxShadow_1, gray_30 } from 'mineral-ui-tokens';
import { ReactComponent as Logo } from './assets/nav-logo.svg'
import Box from 'mineral-ui/Box'

/*
  Global Styles
*/
const theme = {
  // colors
  primaryColor: '#6241E7',
  accentColor1: '#FFF',
  accentColor2: '#4429a3',
  buttonColor: '#000000',
  headingColor: '#000000',
  fontColor: '#4A4A4A',
  thinFont: '#4D5271',
  thickFont: '#313445',
  backgroundColor: 'white',
  //shadow
  shadow: '0 3px 10px 0 rgba(0, 0, 0, 0.15)',
  //weights
  book: 400,
  demi: 600,
  bold: 700,
  ultra: 900,
  // screen sizes
  xl: 1300,
  desktop: 950,
  tablet: 768,
  phone: 414
}

export default theme

// Inject reset.css into main style sheet;
export const GlobalStyle = createGlobalStyle`

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  textarea,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video,
  input {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box;
    font-weight: 300;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  input,
  textarea {
    outline: none;
  }
`

/*
  Icon Buttons
*/
export const DeleteButton = styled(IconDelete)`
  background-color: transparent;
  &:hover {
    color: #cb0000;
  }
`

export const EditButton = styled(IconModeEdit)`
  background-color: transparent;
  &:hover {
    color: darkgrey;
  }
`

export const CopyToClipboard = styled(CopyToClipboardBase)`
  &:hover {
    color: darkgrey;
  }
`

/*
  Header Component Styles
*/
export const HeaderContainer = styled.header`
  height: 200px;
  background-color: ${theme.primaryColor};
  padding: 0 30px;
`
export const Header = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
`
export const MainLogo = styled(Logo)`
  width: 100px;
  height: 24px;
  fill: ${theme.accentColor1};
`

/*
  App Styles
*/
export const Main = styled.main`
  background-color: ${gray_20};
  padding: 0 30px;
`
export const Background = styled.div`
  background-color: ${gray_20};
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

export const AppScreen = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  top: -14vh;
  background: white;
  border-radius: 10px;
  box-shadow: ${boxShadow_1};
  min-height: 400px;
`

/*
  Utility Styles
*/
export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: .5em;
  &.space-between {
    justify-content: space-between;
  }
  &.flex-end {
      justify-content: flex-end;
  }
  &.center {
      justify-content: center;
  }
  & *:not(:last-child){
      margin-right: .5em;
  }
`

export const FlexSubContainer = styled.div`
    display: flex;
    flex-direction: row;
    &:not(:last-child){
        margin-right: .5em;
    }
`

/* 
    Modal Styles
*/
export const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 5% 5%;
  animation: openMap 0.5s;
  @keyframes openMap {
    from {
      opacity: 0;
      transform: scale(1.025);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`

export const Close = styled(IconClose)`
  position: fixed;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 20px;
  cursor: pointer;
  path {
    stroke: white;
    stroke-width: 2px;
  }
`

/*
  Form Styles
*/
export const FormContainer = styled(Form)`
  width: auto;
  height: 57%;
  padding: 6%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.45);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ErrorText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  background-color: transparent;
  font-weight: normal;
`

export const SuccessText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: green;
  background-color: transparent;
  font-weight: normal;
`

export const PasswordInput = styled(Field)`
  height: 30px;
  border-color: lightgrey;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  margin-left: 4px;
  padding-left: 4px;
`

export const TextInput = styled(Field)`
  height: 30px;
  border-color: black;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  margin-left: 4px;
  padding-left: 4px;
`

/*
  Message Styles
*/
export const MessageContainer = styled.div`
  position: relative;
  background: white;
  width: 500px;
  max-width: 100%;
  min-height: 60px;
  border-radius: 10px;
  box-shadow: ${boxShadow_1};
`

export const MessageCloseContainer = styled(Box)`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
  transition-duration: .2s;
  &:hover {
    background: ${gray_30};
  }
`

export const MessageClose = styled(IconClose)`
  width: 24px;
`

export const MessageHeader = styled(Box)`
  display: flex;
  align-items: center;
  min-height: 32px;
  justify-content: space-between;
`

export const MessageIconContainer = styled(Box)`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .5em;
`

/*
  Content Styles
*/
export const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333840;
  background-color: transparent;
  font-weight: normal;
  margin-bottom: 1em;
  & + ${Text} {
    margin-top: .5em;
  };
`

export const SubTitle = styled.div`
  background-color: transparent;
  color: #333840;
  display: flex;
  justify-content: center;
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 2em;
  & + ${Text} {
    margin-top: .5em;
  }
`

export const Title = styled.div`
  background-color: transparent;
  color: #333840;
  display: flex;
  justify-content: center;
  font-size: 1.4em;
  font-weight: 800;
  & + ${SubTitle} {
    margin-top: .5em;
  }
`